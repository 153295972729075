<template>
  <main class="page__content--wider">
    <div class="page__head2">
      <div
        class="page__head__container align-items-center justify-content-between"
      >
        <div class="page__head__container">
          <div class="page__head__icon">
            <img src="assets/img/page-icon.svg" alt="" srcset="" />
          </div>
          <div class="page__head__text">
            <h1 class="page__head__title">Tasks</h1>
            <p class="page__head__subtitle">List of all your task created</p>
          </div>
        </div>
        <div class="page__head__actions">
          <div class="d-flex" style="align-items: center">
            <button
              type="button"
              data-modal="#deleteDocumentModal"
              class="mx-2 button page__body__header__button bulk-delete d-none button--grey text--capital"
            >
              <span class="d-inline-flex mr-2">Bulk Delete </span>
              <ion-icon
                name="trash-outline"
                class="text--600 d-inline-flex text--regular ml-2"
              ></ion-icon>
            </button>
            <button
              type="button"
              class="mx-2 button page__body__header__button button--grey text--capital"
              v-if="userCanCreateTask"
              @click="openTaskModal"
            >
              <span class="d-inline-flex mr-2">new task</span>
              <ion-icon
                name="add-outline"
                class="text--600 text--medium ml-2"
              ></ion-icon>
            </button>
            <div class="align-self-center mx-2 dropdown">
              <b-dropdown
                :html="filter_by_label"
                no-caret
                class="m-2 page__body__header__button dropdown--button-grey w-100"
                v-model="sort"
              >
                <b-dropdown-item
                  v-for="item in cats"
                  :key="item.value"
                  v-bind:value="item.value"
                  @click="filterByCategory(item.value, item.text)"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <!--<div class="align-self-center mx-2 dropdown">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                custom columns
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item text--capital"
                  data-modal="#addNewCustomModal"
                  >new column preset</a
                >
                <a class="dropdown-item text--capital">custom columns</a>
              </div>
            </div>-->
            <div class="align-self-center mx-2 dropdown">
              <button
                class="button button--grey text--capital text--small d-flex align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                export
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  href="javascript:void(0)"
                  class="dropdown-item text--capital"
                  v-if="userCanExportToPDF"
                  @click="pdfDownload"
                  >PDF</a
                >
                <a
                  href="javascript:void(0)"
                  @click="processDownload"
                  v-if="userCanExportToExcel"
                  class="dropdown-item text--capital"
                  >excel</a
                >
                <!-- <download-excel
                  class="dropdown-item text--capital"
                  style="cursor: pointer"
                  :data="download"
                  :fields="json_fields"
                  name="tasks.xls"
                >
                  excel
                </download-excel> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page__body">
      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              Summary
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              Assigned To
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            >
              Due Date
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              Linked To
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            >
              Status
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              Created by
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            >
              Note
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody v-if="this.loadingTasks">
          <tr>
            <td colspan="12">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="this.allTasks.data !== ''">
          <tr v-for="(task, index) in this.allTasks.data" :key="index">
            <td>
              <div>
                <span
                  class="table__body__text table__text text--capital d-block"
                  style="white-space: pre-wrap"
                  >{{ task.taskTittle }}</span
                >
                <!-- <span class="table__body__text table__text text--capital d-block text--small">Jerryson ibe</span> -->
              </div>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                task.assignedToName
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                task.dueDate | formatDate
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                task.linkName
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                task.taskStatus
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                task.createdByName
              }}</span>
            </td>
            <td>
              <a
                class="table__body__text table__text text--capital text--success"
                @click="openTaskNote(task)"
              >
                <img src="@/assets/img/paper-green.svg" alt="" srcset="" />
                View Note
              </a>
            </td>
            <td style="padding-right: 0 !important">
              <div class="align-self-center dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    @click="toggleReassignedModal(task)"
                    >Reassign To</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    v-if="task.taskStatus !== 'Closed'"
                    @click="toggleCloseTaskModal(task)"
                    >Close Task</a
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="this.allTasks.data == ''">
          <tr>
            <td colspan="12">
              <div
                class="d-flex flex-column align-items-center justify-content-center p5"
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >No data available</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination
        :allItems="allTasks"
        :size="createdTask.pageSize"
        @update-created-task="getUpdated"
        @change-page-index="changePageIndex"
      />

      <DeleteModal
        :show="!userCanViewTask"
        :title="'Unauthorized'"
        @close="goToDashboard"
      >
        <template v-slot:modal-body>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Unauthorized
              </h5>
              <!-- <h5 class="text-danger">
                You don
              </h5> -->
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button form__button form__button--lg save-changes fitrem"
                  @click.prevent="goToDashboard"
                >
                  Go to Dashboard
                </button>
              </div>
            </div>
          </div>
        </template>
      </DeleteModal>

      <viewTaskNote
        :task="iTask"
        :show="showTaskNoteModal"
        @close="closeTaskNote"
        @task-completed-successfully="taskCompleted"
        @error-completing-task="taskError"
      />

      <closeTaskModal
        :task="iTask"
        :show="showCloseTaskModal"
        @close="toggleCloseTaskModal"
        @task-closed-successfully="taskClosedSuccessfully"
        @error-closing-task="taskClosedError"
      />

      <reassignTaskModal
        :task="iTask"
        :show="showReassignTaskModal"
        @close="toggleReassignedModal"
        @task-reassigned-successfully="taskReassignedSuccessfully"
        @error-reassigning-task="taskReassignedError"
      />

      <CreateTask
        :show="newTaskModal"
        @close="openTaskModal"
        @error-creating-task="errorCreatingTask"
        @task-created-successfully="taskCreatedSuccessfully"
      />

      <!-- Alert notification -->
      <div class="toaster show">
        <div
          class="toaster__container"
          v-if="alert.show"
          v-bind:class="{
            'toaster--success': alert.status === 'success',
            'toaster--error': alert.status === 'fail',
            'toaster--warn': alert.status === 'warn',
          }"
        >
          <div class="text-right">
            <span class="fa fa-times" @click="alert.show = false"></span>
          </div>
          <div>
            <span class="d-block text--small text--600 text--white">{{
              alert.title
            }}</span>
            <span class="d-block text--tiny text--400 text--white">{{
              alert.description
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ApiService from "@/core/services/api.service";
import moment from "moment";
import * as XLSX from "xlsx";
// import JsonExcel from "vue-json-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
export default {
  name: "all_tasks",
  data() {
    return {
      userKey: "2150b9d09ecb483d8eff635c9b067d9b",
      allTasks: {},
      loadingTasks: true,
      iTask: {},
      showTaskNoteModal: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      json_fields: {
        Summary: "summary",
        "Assigned To": "assigned_to",
        "Due Date": "due_date",
        "Linked To": "linked_to",
        Status: "status",
        "Created By": "created_by",
        Note: "note",
      },
      filter_by_label: "Filter <ion-icon name='caret-down-outline'/>",
      showCloseTaskModal: false,
      showReassignTaskModal: false,
      newTaskModal: false,
      pages: 1,
      size: 10,
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      sort: "date",
      createdTask: {
        pageIndex: 1,
        pageSize: 20,
        sortName: "",
        filters: [],
      },
      cats: [
        {
          value: "Task Created",
          text: "Task Created",
        },
        {
          value: "Task Assigned",
          text: "Task Assigned",
        },
        {
          value: "",
          text: "All",
        },
      ],
    };
  },
  components: {
    viewTaskNote: () =>
      import(
        /* WebpackChunkName: "viewTaskNote" */ "@/view/pages/deposit/task/viewTaskNote.vue"
      ),
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
    DeleteModal: () =>
      import(
        /* WebpackChunkName: "viewTaskNote" */ "@/components/DeleteModal/_base-delete-modal.vue"
      ),
    closeTaskModal: () =>
      import(
        /* WebpackChunkName: "closeTaskModal" */ "@/view/pages/deposit/task/closeTask.vue"
      ),
    reassignTaskModal: () =>
      import(
        /* WebpackChunkName: "reassignTaskModal" */ "@/view/pages/deposit/task/reassignTask.vue"
      ),
    CreateTask: () =>
      import(
        /* WebpackChunkName: "CreateTask" */ "@/view/pages/deposit/task/createTask.vue"
      ),
  },
  methods: {
    getUpdated(value) {
      this.createdTask.pageSize = value;
      ApiService.post("TaskManagement/get-filtered-created", {
        pageIndex: this.allTasks.currentPage,
        pageSize: this.createdTask.pageSize,
        filter: [],
      })
        .then((response) => {
          this.allTasks = response.data;
          this.loadingTasks = false;
        })
        .catch((error) => {
          this.alertError(error.response.data.data);
        });
    },
    changePageIndex(value, pageSize) {
      this.changeCurrentPage(value, pageSize);
    },
    openTaskModal() {
      this.newTaskModal = !this.newTaskModal;
    },
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Tasks Download",
        Subject: "Tasks Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("Tasks");

      let sheetData = [
        [
          "Summary",
          "Assigned To",
          "Due Date",
          "Linked To",
          "Status",
          "Created By",
          "Note",
        ],
      ];
      if (this.allTasks) {
        this.allTasks.map((item) => {
          let new_arr = [];

          new_arr.push(item.taskTittle);
          new_arr.push(item.assignedToName);
          new_arr.push(moment(item.dueDate).format("Do-MMM-YYYY"));
          new_arr.push(item.linkName);
          new_arr.push(item.taskStatus);
          new_arr.push(item.createdByName);
          new_arr.push(item.taskDescription);

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["Tasks"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "tasks.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Summary", dataKey: "summary" },
        { title: "Assigned To", dataKey: "assigned_to" },
        { title: "Due Date", dataKey: "due_date" },
        { title: "Linked To", dataKey: "linked_to" },
        { title: "Status", dataKey: "status" },
        { title: "Created By", dataKey: "created_by" },
        { title: "Note", dataKey: "note" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("tasks.pdf");
    },
    getAllCreatedTasks() {
      ApiService.post(
        "TaskManagement/get-filtered-assigned-created",
        this.createdTask
      )
        .then((response) => {
          this.allTasks = response.data;
          this.loadingTasks = false;
        })
        .catch((error) => {
          this.loadingTasks = false;
          this.alertError(error.response.data.message);
        });
      if (!this.userCanViewTask)
        return this.alertWarning(
          "You do not have permission to view this page"
        );
    },
    openTaskNote(value) {
      this.iTask = value;
      this.showTaskNoteModal = !this.showTaskNoteModal;
    },
    closeTaskNote() {
      this.showTaskNoteModal = false;
    },
    taskCompleted(value) {
      this.alertSuccess(value);
      this.getAllCreatedTasks();
    },
    taskError(value) {
      this.alertError(value);
    },
    toggleCloseTaskModal(value) {
      this.iTask = value;
      this.showCloseTaskModal = !this.showCloseTaskModal;
    },
    taskClosedSuccessfully(value) {
      this.alertSuccess(value);
      this.getAllCreatedTasks();
    },
    taskClosedError(value) {
      this.alertError(value);
    },
    toggleReassignedModal(value) {
      this.iTask = value;
      this.showReassignTaskModal = !this.showReassignTaskModal;
    },
    taskReassignedSuccessfully(value) {
      this.alertSuccess(value);
      this.getAllCreatedTasks();
    },
    taskReassignedError(value) {
      this.alertError(value);
    },
    taskCreatedSuccessfully(value) {
      this.alertSuccess(value);
      this.getAllCreatedTasks();
    },
    errorCreatingTask(value) {
      this.alertError(value);
    },
    changeCurrentPage(pages = null) {
      if (pages === "next") {
        let nextPage = this.allTasks.hasNext
          ? this.allTasks.currentPage + 1
          : 0;

        ApiService.post("TaskManagement/get-filtered-created", {
          pageIndex: nextPage,
          pageSize: this.createdTask.pageSize,
          filter: [],
        })
          .then((response) => {
            this.allTasks = response.data;
            this.loadingTasks = false;
          })
          .catch((error) => {
            this.alertError(error.response.data.data);
          });
      } else if (pages === "previous") {
        this.createdTask.pageIndex = this.allTasks.hasPrevious
          ? this.allTasks.currentPage - 1
          : 0;
        ApiService.post("TaskManagement/get-filtered-created", this.createdTask)
          .then((response) => {
            this.allTasks = response.data;
            this.loadingTasks = false;
          })
          .catch((error) => {
            this.alertError(error.response.data.data);
          });
      } else if (pages !== null) {
        const page = {
          pageIndex: pages,
          pageSize: this.createdTask.pageSize,
          filter: [],
        };
        ApiService.post("TaskManagement/get-filtered-created", page)
          .then((response) => {
            this.allTasks = response.data;
            this.loadingTasks = false;
          })
          .catch((error) => {
            this.alertError(error.response.data.data);
          });
      }
    },
    filterByCategory(id, name) {
      this.filter_by_label = name + " <ion-icon name='caret-down-outline'/>";
      this.createdTask.sortName = id;
      this.getAllCreatedTasks();
    },
  },
  mounted() {
    this.getAllCreatedTasks();
  },
  computed: {
    ...mapState({
      dashboardStats: (state) => state.deposit?.dashboardStats?.data || {},
      // loading: (state) => state.deposit.loadingdashboardStats,
      user: (state) => state.auth.user,
    }),
    userCanCreateTask() {
      const task = this.user.scopes.find(
        (item) => item.scopeName === Role.Tasks
      );
      const { permissionsList } = task;
      return permissionsList.CanCreateTask;
    },
    userCanViewTask() {
      const task = this.user.scopes.find(
        (item) => item.scopeName === Role.Tasks
      );
      const { permissionsList } = task;
      return permissionsList.CanViewTask;
    },
    userCanExportToExcel() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
    download() {
      let data = [];

      if (this.allTasks.data) {
        this.allTasks.data.forEach((item) => {
          let new_obj = {};
          new_obj.summary = item.taskTittle;
          new_obj.assigned_to = item.assignedToName;
          new_obj.due_date = moment(item.dueDate).format("Do-MMM-YYYY");
          new_obj.linked_to = item.linkName;
          new_obj.status = item.taskStatus;
          new_obj.created_by = item.createdByName;
          new_obj.note = item.taskDescription;

          data.push(new_obj);
        });
      }

      return data;
    },
  },
};
</script>
<style scoped>
.has-checkbox .checkmark {
  top: -10px;
}
.customtable tbody td {
  padding-right: 15px !important;
}
</style>
